import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/contact_title_image.jpg'
class ContactTemplate extends React.Component{
  render(){
    return(
      <Layout>
      <SEO title="Contact Us" />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">Contact</h1>
            <h6 className="subtitle">Not sure what you need? Our team can partner with you to figure that out.</h6>
        </div>
      </section>
      
      <section className="section contact-page">
          <div className="container">
        <div className="contact-infos">
            <div className="single">
                <i className="address"></i>
                <a href="https://www.google.com/maps/place/1900+Campus+Commons+Dr+%23100,+Reston,+VA+20191,+Sjedinjene+Dr%C5%BEave/@38.9443739,-77.3369641,16.25z/data=!4m5!3m4!1s0x89b64834752d32bf:0x1fddb5d96344aca1!8m2!3d38.9447206!4d-77.3367495" target="_blank">1900 Campus Commons DR Suite 100
Reston VA 20191</a>
            </div>

            <div className="single">
                <i className="phone"></i>
                <a href="tel:571-559-3800" target="_blank">571-559-3800</a>
            </div>
        </div>

            <form className="contact-form default-form" name="contact" method="POST" action="/thank-you" data-netlify="true" data-netlify-honeypot="bot-field">
                <span>Fields marked with (*) is required.</span>
                <fieldset>
                <input type="hidden" name="form-name" value="contact" />
                    <div className="columns is-multiline">
                        <label className="column is-6" htmlFor="name">
                            <input type="text" name="name" placeholder="First name" required/>
                        </label>
                        <label className="column is-6" htmlFor="surname">
                            <input type="text" name="surname" placeholder="Last name" required/>
                        </label>
                        <label className="column is-4" htmlFor="email">
                            <input type="email" name="email" placeholder="Email address" required/>
                        </label>

                        <label className="column is-4" htmlFor="company">
                            <input type="text" name="company" placeholder="Company name" />
                        </label>

                        <label className="column is-4" htmlFor="postal_code">
                            <input type="text" name="postal_code" placeholder="Postal code" />
                        </label>
                        <label className="column is-12" htmlFor="message">
                            <textarea name="meeage" placeholder="How can we help you?" required></textarea>
                        </label>

                        <div className="send-message-box column is-12">
                            <label htmlFor="privacy_policy" className="checkbox">I understand and agree to the <Link to="/privacy-policy">Privacy Policy</Link>.
                            <input type="checkbox" className="checkbox-input" name="privacy_policy" required />
                            <span class="checkmark"></span>
                            </label>
                            <button className="button green">Send Message</button>
                        </div>
                    </div>
                </fieldset>
            </form>
          </div>
      </section>
      </Layout>
    )
  }
}

export default ContactTemplate
